<template>
  <div class="product-edit-page">
    <h2>Edit Product</h2>

    <product-form
      v-if="product"
      v-model="product"
      class="my-3"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import ProductForm from '../components/ProductForm.vue'

export default {
  name: 'ProductEdit',

  components: {
    ProductForm
  },

  computed: {
    productId () {
      return this.$route.params.id
    }
  },

  data () {
    return {
      product: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$products.get(this.productId)
        this.product = resp.data.data
      }
      catch (e) {
        console.error(e.message)
      }
    },

    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const resp = await this.$products.update(this.productId, this.product)
        this.product = resp.data.data
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
