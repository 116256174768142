<template>
  <b-card class="product-form">
    <!-- Name -->
    <b-form-group
      v-for="locale in locales"
      :key="`name_${locale}`"
    >
      <label>Name</label> <span class="badge badge-dark">{{ locale }}</span>
      <b-form-input
        v-model="internalValue[`name_${locale}`]"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Slug -->
    <b-form-group>
      <label>Slug</label>
      <b-form-input
        v-model="internalValue.slug"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Category -->
    <b-form-group class="mb-0">
      <label>Category</label>
      <b-form-group>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="internalValue.categories"
          :options="categoryOptions"
          name="flavour-1"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-form-group>

    <!-- Price -->
    <b-form-group>
      <label>Price</label>
      <b-form-input
        v-model="internalValue.price"
        type="number"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Images -->
    <b-form-group>
      <label>Images</label>
      <file-selector
        v-model="internalValue.images"
        multiple
      />
    </b-form-group>

    <!-- Description -->
    <b-form-group
      v-for="locale in locales"
      :key="`teaser_${locale}`"
    >
      <label>Description</label> <span class="badge badge-dark">{{ locale }}</span>
      <b-form-textarea
        v-model="internalValue[`teaser_${locale}`]"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Description -->
    <b-form-group
      v-for="locale in locales"
      :key="`body_${locale}`"
    >
      <label>Body</label> <span class="badge badge-dark">{{ locale }}</span>
      <b-form-textarea
        v-model="internalValue[`body_${locale}`]"
        @input="emitInput"
      />
    </b-form-group>
  </b-card>
</template>

<script>
import FileSelector from './FileSelector.vue'

export default {
  name: 'ProductForm',

  components: {
    FileSelector
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  computed: {
    categoryOptions () {
      return this.categories.map(category => {
        return { value: category.id, text: category[`name_${this.currentLocale}`] }
      })
    }
  },

  data () {
    return {
      categories: [],
      internalValue: {
        name_th: '',
        name_en: '',
        slug: '',
        categories: [],
        teaser_th: '',
        teaser_en: '',
        images: [],
        price: 100,
        body_th: '',
        body_en: ''
      }
    }
  },

  created () {
    if (this.value) {
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    }

    if (this.internalValue.categories.length > 0) {
      const categories = this.internalValue.categories.map(cat => cat.id)
      this.internalValue.categories = categories
    }

    this.emitInput()
  },

  mounted () {
    this.fetchCategories()
  },

  methods: {
    async fetchCategories () {
      try {
        const resp = await this.$categories.list()
        this.categories = resp.data.data
      }
      catch (e) {
        console.log(e.message)
      }
    },

    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
